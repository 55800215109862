<div class="content-wrapper d-flex align-items-center auth">
  <div class="row flex-grow">
    <div class="col-lg-4 mx-auto">
      <div class="auth-form-light text-left p-5">
        <div class="brand-logo text-center">
          <img src="assets/images/Logo.png">
        </div>
        <h6 class="font-weight-light page-title mb-3">Forgot Password</h6>
        <form class="pt-3" [formGroup]="forgotPassword">
          <div class="form-group">
            <input type="email" class="form-control form-control-lg" formControlName="email" id="exampleInputEmail1"
              placeholder="Email ID">
            <div class="mt-1">
              <mat-error class="text-small text-danger" *ngIf="isValid && error('email','required')">Email
                ID is required.
              </mat-error>
            </div>
          </div>
          <div class="mt-3">
            <a class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
              (click)="onSubmit()">Sent</a>
          </div>
          <div class="mt-3">
            <a class="btn btn-block btn-link btn-lg font-weight-medium auth-form-btn" routerLink="/login">Cancel</a>
          </div>
          <div class="my-2 d-flex justify-content-between align-items-center">
          </div>
          <div class="mb-2">
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
