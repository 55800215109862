import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TravelTeamMembersService {
  url = environment.url + 'api/TravelTeamMember/';
  
  travelMember = 'GetMemberList';
  addMember = 'AddMember';
  editMember = 'GetMemberById';
  delete = 'DeleteMemberById';

  constructor(private _http: HttpClient) { }

  getTravelMembers(SearchType:string,PageNumber:any,PageSize:any,SearchText:any,SortColumn:any,SortOrder:any){
    let url = this.url;
    url=url+this.travelMember;
    let newUrl = url + '?SearchType='+SearchType+'&PageNumber='+PageNumber+'&PageSize='+PageSize+'&SearchText='+SearchText+'&SortColumn='+SortColumn+'&SortOrder='+SortOrder
     return this._http.get(newUrl);
  }

  addTravelMember(data:any){
    return this._http.post(this.url + this.addMember,data);
  }

  editTravelMember(id: string) {
    return this._http.get(`${this.url + this.editMember +"?MemberId="}${id}`)
  }

  deleteTravelMember(id: string) {
    return this._http.delete(`${this.url + this.delete +"?MemberId="}${id}`);
  }

 
  
}
