import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CommonService } from '../services/common.service';
import { TravelTeamMembersService } from '../services/travel-team-members.service';


@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.css']
})
export class SideNavbarComponent {
  userName: any;
  email: any;
  roleId: any = localStorage.getItem('roleId');
  userId: any;
  menuList: any = [];
  toggleDropdown:boolean = false;
  isMobile:boolean =false;
  ismenu:boolean= true;
 
  constructor(private router: Router,private changeDetectorRef: ChangeDetectorRef ,private _commonService:CommonService,private ngZone:NgZone,private membersService: TravelTeamMembersService) {
    this.email = this._commonService.decryptAES256(localStorage.getItem('email'));
    this.userId = this._commonService.decryptAES256(localStorage.getItem('UserId'));
    this.userName = this._commonService.decryptAES256(localStorage.getItem('userName'));
    window.onresize = (e) =>
    {
        this.ngZone.run(() => {
          this.isMobile = window.innerWidth < 991 ?  true : false;
        });
    }
 

  }

  ngOnInit() {
    this.isMobile = window.innerWidth < 991 ?  true : false;
    this.Menu();
    this.GetMemberById(); 
  }

 
  Menu() {
    this.menuList = [];
    this._commonService.GetModulewiseRolePermission(this.roleId)
    .subscribe((result: any) => {
    this.menuList = result;
    this.changeDetectorRef.detectChanges();
  });
  }

  GetMemberById() {
    this.membersService.editTravelMember(this.userId).subscribe((result: any) => {
      this.userName = result.data.name;
      this.changeDetectorRef.detectChanges();
  });
  }
  editUser(){
    this.toggleDropdown = false;
  }

  onLogOut() {
    Swal.fire({
      title: 'Are you sure to signout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        this.router.navigate(['']);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }


  toggleDrawer(ismenu:boolean) {
    this.ismenu = !ismenu;
  }

  

}
