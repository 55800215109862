export const environment = {
  production: false,
  key: "8080808080808080",
  iv: "8080808080808080",

  //Testing Url
  url: "https://travel-api.sparkleweb.co.in/"

  //Local Url
  // url:"https://localhost:7085/"
  // url: "https://localhost:5001/"

  //Live Url
  // url:"http://192.168.29.132:82/"
  // url:"http://10.0.0.4:82/"

  //client url
  // url: "https://weltd.welspun.com/api/"
};
