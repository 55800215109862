import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimwhitespace'
})
export class TrimwhitespacePipe implements PipeTransform {

  transform(value: string): string {
    return value.replace(/\s/g,'')
  }

}
