import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountsService } from 'src/app/services/accounts.service';
import { CommonService } from 'src/app/services/common.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  hide = true;
  login: FormGroup;
  isValid = false;
  value: any;
  roleId: any;
  roleName: any;

  constructor(private router: Router, private _fb: FormBuilder, private _service: AccountsService, private commonService: CommonService) {
    this.login = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (localStorage.getItem('accessToken') != null) {
      this.router.navigate(['travel-desk/dashboard']);
    }
  }


  submit() {
    this.isValid = true;
    if (this.login.invalid) {
      return;
    }
    else {
      var data = {
        email: this.commonService.encryptAES256(this.login.value.email),
        password: this.commonService.encryptAES256(this.login.value.password),
      }
      this._service.Login(data).subscribe((result: any) => {
        if (result.statusCode == 200) {
          const token = (<any>result).data.token;
          const userId = (<any>result).data.userid;
          const refreshToken = (<any>result).data.refreshToken;
          this.roleId = JSON.parse(window.atob(token.split('.')[1]))["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
          this.roleName = (<any>result).data.roleName;
          localStorage.setItem('email', result.data.email);
          localStorage.setItem('token', JSON.stringify(result.data));
          localStorage.setItem("accessToken", token);
          localStorage.setItem("UserId", userId);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("roleId", this.roleId);
          localStorage.setItem("roleName", this.commonService.decryptAES256(this.roleName));
          localStorage.setItem("userName", result.data.name);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Successfully Login',
            showConfirmButton: false,
            timer: 500
          });
          this.router.navigate(['travel-desk/dashboard']);
        }
        else if (result.status == 'error' || 'Error' || '') {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Invalid email or password.',
            showConfirmButton: true,
          });
        }
      },
        error => {
          if (error.status == 400) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Invalid email or password.',
              showConfirmButton: true,
            });
          }
        }
      );
    }
  }

  onKeypressEvent(event: any) {
    if (event.key == "Enter") {
      this.submit();
    }
  }
}
