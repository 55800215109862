import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from 'src/app/services/accounts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetPassword: any;
  token: any;
  email: any;
  hideNewPassword = true;
  hideConfirmPassword = true;
  submitted: boolean = false;
  

  constructor(private _fb: FormBuilder, private _service: AccountsService, private route: ActivatedRoute, private router: Router) {
    this.resetPassword = this._fb.group({
      email: [''],
      token: [''],
      newPassword: ['', [Validators.required,Validators.pattern(
        '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z0-9\d$@$!%*?&].{7,}'
      ),UsernameValidator.cannotContainSpace]],
      confirmPassword: ['', [Validators.required]]
    },
      {
        validators: ConfirmPasswordValidator('newPassword', 'confirmPassword')
      });
  }

  public error = (control: string, error: string) => {
    return this.resetPassword.controls[control].hasError(error);
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    localStorage.clear();
    this.route.queryParams.subscribe((params: any) => {
      this.email = params.email;
      this.token = params.token.replace(/ /g, "\+");
    })
  }

  onSubmit() {
    this.submitted = true;
    if (this.resetPassword.invalid) {
      return
    }
    else {
      this._service.resetPassword(this.resetPassword.value).subscribe((res: any) => {
        if (res.status == 'success' || 'Success') {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: res.message,
            confirmButtonText: 'OK',
          }).then((res) => {
            if (res.isConfirmed) {
              this.router.navigate(['login']);
            }
          })
        }
        if (res.status == 'Error') {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: res.message,
            confirmButtonText: 'OK',
          })
        }

      })
    }
  }

}

export function ConfirmPasswordValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    let control = formGroup.controls[controlName];
    let matchingControl = formGroup.controls[matchingControlName]
    if (
      matchingControl.errors &&
      !matchingControl.errors['confirmPasswordValidator']
    ) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmPasswordValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}


export class UsernameValidator {
  static cannotContainSpace(control: AbstractControl) : ValidationErrors | null {
      if((control.value as string).indexOf(' ') >= 0){
          return {cannotContainSpace: true}
      }

      return null;
  }

}