<div class="container-scroller">
  <div class=" page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-center auth">
      <div class="row flex-grow">
        <div class="col-lg-4 mx-auto">
          <div class="auth-form-light text-left p-5">
            <div class="brand-logo text-center">
              <img src="assets/images/Logo.png">
            </div>
            <h4>Hello! let's get started</h4>
            <h6 class="font-weight-light">Sign in to continue.</h6>
            <form class="pt-3" [formGroup]="login">
              <div class="form-group">
                <input type="email" class="form-control form-control-lg" formControlName="email" id="exampleInputEmail1"
                  placeholder="Email or Phone" required>
                <div class="mt-1" *ngIf="isValid&&login.controls['email'].invalid">
                  <div class="text-small text-danger">
                    Email is required.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group mb-3">
                  <input type="password" class="form-control form-control-lg" formControlName="password" id="password"
                    id="exampleInputPassword1" placeholder="Password" matInput [type]="hide ? 'password' : 'text'"
                    (keypress)="onKeypressEvent($event)">
                  <span class="input-group-text" (click)="hide = !hide">
                    <mat-icon *ngIf="!hide">visibility</mat-icon>
                    <mat-icon *ngIf="hide">visibility_off</mat-icon>
                  </span>
                </div>
                <div class="mt-1" *ngIf="isValid&&login.controls['password'].invalid">
                  <div class="text-small text-danger">
                    Password is required.
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <a class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                  (click)="submit()">SIGN IN</a>
              </div>
              <div class="my-2 d-flex justify-content-between align-items-center">
                <div class="form-check">
                  <label class="form-check-label text-muted d-none">
                    <input type="checkbox" class="form-check-input " checked> Keep me signed in
                  </label>
                </div>
                <a href="javascript:void(0);" class="auth-link text-black" routerLink="/forgot-password">Forgot
                  password?</a>
              </div>
              <div class="mb-2">
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- content-wrapper ends -->
  </div>
  <!-- page-body-wrapper ends -->
</div>
