import { Component } from '@angular/core';
import { AbstractControl, FormBuilder,  FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AccountsService } from 'src/app/services/accounts.service';
import { CommonService } from 'src/app/services/common.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {
  changePassword: any;
  email :any;
  hideOldPassword = true;
  hideNewPassword = true;
  hideConfirmPassword = true;
  submitted: boolean = false;
  isValid = false;

  constructor(private _fb: FormBuilder, private _service: AccountsService,private commonService: CommonService) {
    this.email = this.commonService.decryptAES256(localStorage.getItem('email'));
    this.changePassword = this._fb.group({
      email: [this.email],
      oldpassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required,Validators.pattern(
        '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z0-9\d$@$!%*?&].{7,}'
      ),UsernameValidator.cannotContainSpace]],
      confirmPassword: ['', [Validators.required]]
    },
    {
      validators: ConfirmPasswordValidator('newPassword', 'confirmPassword')
    });
  }

  public error = (control: string, error: string) => {
    return this.changePassword.controls[control].hasError(error);
  }

  onSubmit(){
    this.submitted = true;
    this.isValid = true;
    if (this.changePassword.invalid) {
      return;
    }
    else {
      this._service.changePassword(this.changePassword.value).subscribe((res: any) => {
        if (res.status == 'success' || 'Success') {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: res.message,
            confirmButtonText: 'OK',
          }).then((res) => {
            if (res.isConfirmed) {
              location.reload();
            }
          })
        }
       if (res.status =='Error') {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: res.message,
            confirmButtonText: 'OK',
          })
        }

      })
    }
  }

  

  
}
export function ConfirmPasswordValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    let control = formGroup.controls[controlName];
    let matchingControl = formGroup.controls[matchingControlName]
    if (
      matchingControl.errors &&
      !matchingControl.errors['confirmPasswordValidator']
    ) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmPasswordValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}



export class UsernameValidator {
  static cannotContainSpace(control: AbstractControl) : ValidationErrors | null {
      if((control.value as string).indexOf(' ') >= 0){
          return {cannotContainSpace: true}
      }

      return null;
  }

}
