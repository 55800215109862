<div class="container-scroller">

  <!-- Navbar -->
  <nav class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div class=" navbar-brand-wrapper d-flex align-items-center justify-content-center" *ngIf="ismenu">
      <a class="navbar-brand brand-logo" routerLink="travel-desk/dashboard"><img src="assets/images/Logo.png"
          alt="logo" /></a>
    </div>
    <div class=" navbar-brand-wrapper d-flex align-items-center justify-content-center" *ngIf="!ismenu">
      <a class="navbar-brand brand-logo logo-2" routerLink="travel-desk/dashboard"><img src="assets/images/favicon.png"
          alt="logo" /></a>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-stretch">
      <button class="navbar-toggler align-self-center" type="button" data-toggle="minimize">
        <span class="margin" [ngClass]="!ismenu ? 'slide-block':''">
          <mat-icon class="fs-5" (click)="toggleDrawer(ismenu)">menu</mat-icon>
        </span>
      </button>
      <!-- Navbar dropdown -->
      <ul class="navbar-nav navbar-nav-right">
        <li class="nav-item nav-profile dropdown">
          <a class="nav-link dropdown-toggle pointer" id="profileDropdown" data-toggle="dropdown" aria-expanded="false"
            appDropdown #r="appDropDown">
            <div class="nav-profile-img">
              <img src="assets/images/faces-clipart/pic-5.png" alt="image">
            </div>
            <div class="nav-profile-text">
              <p class="mb-1 text-black">{{email}}</p>
            </div>
            <mat-icon *ngIf="!r.toggleDropdown">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="r.toggleDropdown">keyboard_arrow_up</mat-icon>
          </a>
          <div class="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown"
            [ngClass]="{'show':r.toggleDropdown}">
            <ng-container *ngIf="isMobile">
              <a class="dropdown-item" *ngFor="let menu of menuList" 
                [routerLink]="[menu?.moduleURL]">
                <i class="mr-2 text-primary">
                  <mat-icon class="fs-5" [innerHTML]="menu.icon"></mat-icon>
                </i> {{menu.moduleName}}</a>
            </ng-container>
            <a class="dropdown-item" routerLink="change-password">
              <i class=" mr-2 text-danger">
                <mat-icon class="fs-5">vpn_key</mat-icon>
              </i> Change Password </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="onLogOut()">
              <i class="mr-2 text-primary">
                <mat-icon class="fs-5">exit_to_app</mat-icon>
              </i> Signout</a>
          </div>

        </li>

      </ul>
    </div>
  </nav>

  <!-- Mobile view -->
  <div *ngIf="ismenu" class="page-body-wrapper">
    <mat-drawer-container autosize>
      <mat-drawer #drawer mode="side" opened="true" class="position-fixed mt-5">
        <nav class="sidebar  sidebar-offcanvas">
          <ul class="nav">
            <li class="nav-item nav-profile mt-2">
              <a class="nav-link" (click)="editUser()">
                <div class="nav-profile-image">
                  <img src="assets/images/faces-clipart/pic-5.png" alt="profile">
                  <span class="login-status online"></span>
                </div>
                <div class="nav-profile-text d-flex flex-column">
                  <span class="font-weight-bold mb-2">{{userName}}</span>
                </div>
                <i class="text-success nav-profile-badge"></i>
              </a>
            </li>
            <div *ngFor="let menu of menuList">
              <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" href="" [routerLink]="[menu.moduleURL]">
                  <span class="menu-title">{{menu.moduleName}}</span>
                  <i class="menu-icon">
                    <mat-icon [innerHTML]="menu.icon"></mat-icon>
                  </i>
                </a>
              </li>
            </div>
          </ul>
        </nav>
      </mat-drawer>
    </mat-drawer-container>
    <div class="main-panel">
      <div class="content-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <!-- Web view -->
  <div *ngIf="!ismenu" class="page-body-wrapper">
    <mat-drawer-container autosize>
      <mat-drawer #drawer mode="side" opened="true" class="position-fixed mt-5">
        <nav class="sidebar sidebar1 sidebar-offcanvas">
          <ul class="nav nav1">
            <div>
              <li class="nav-item nav-profile mt-2">
                <a class="nav-link" (click)="editUser()">
                  <div class="nav-profile-image">
                    <img src="assets/images/faces-clipart/pic-5.png" alt="profile">
                    <span class="login-status online"></span>
                  </div>
                  <div class="nav-profile-text d-flex flex-column">
                    <!-- <span class="font-weight-bold mb-2">{{userName}}</span> -->
                  </div>
                  <i class="text-success nav-profile-badge"></i>
                </a>
              </li>
            </div>
            <div *ngFor="let menu of menuList">
              <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" href="" [routerLink]="[menu.moduleURL]">
                  <!-- <span class="menu-title">{{menu.moduleName}}</span> -->
                  <i class="menu-icon">
                    <mat-icon [innerHTML]="menu.icon"></mat-icon>
                  </i>
                </a>
              </li>
            </div>
          </ul>
        </nav>
      </mat-drawer>
    </mat-drawer-container>
    <div class="main-panel">
      <div class="content-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>