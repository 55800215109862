import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SideNavbarComponent } from './side-navbar/side-navbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './accounts/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ChangePasswordComponent } from './accounts/change-password/change-password.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import {  MatIconModule } from '@angular/material/icon';
import { AuthGuardGuard } from './guards/auth-guard.guard';
import { CommonModule } from '@angular/common';
import { ForgotPasswordComponent } from './accounts/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './accounts/reset-password/reset-password.component';
import { JwtInterceptor } from './interceptor/jwt.interceptor';
import { LoaderComponent } from './loader/loader.component';
import { LoaderInterceptor } from './loader/loader.interceptor';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderService } from './loader/loader.service';
import { NgxMaskModule } from 'ngx-mask';
import { TrimwhitespacePipe } from './pipe/trimwhitespace.pipe';
import { DropdownDirective } from './directive/dropdown.directive';



@NgModule({
  declarations: [
    AppComponent,
    SideNavbarComponent,
    LoginComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LoaderComponent,
    TrimwhitespacePipe,
    DropdownDirective,
    
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSidenavModule,
    MatIconModule,
    CommonModule,
    MatProgressSpinnerModule,
    NgxMaskModule.forRoot(),
    
  ],
  providers: [
    AuthGuardGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor,multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    LoaderService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

