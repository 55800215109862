import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoaderService {


    private loadCount: number = 0;
    loadState: BehaviorSubject<boolean> = new BehaviorSubject(false);
    constructor() { }
    ShowLoader() {
      this.loadCount+=1;
      this.loadState.next(true);
      let body = document.getElementsByTagName('body')[0];
      let html = document.getElementsByTagName('html')[0];
      if(this.loadState.value)
      {
        body.classList.add('body-landing');
        html.classList.add('body-landing');
      }
    }
  
    HideLoader() {
      this.loadCount = (this.loadCount ? --this.loadCount : 0);
      if (!this.loadCount) this.loadState.next(false);
      let body = document.getElementsByTagName('body')[0];
      let html = document.getElementsByTagName('html')[0];
      if(!this.loadState.value){
        body.classList.remove("body-landing");
        html.classList.remove("body-landing");
      }
    }

}
