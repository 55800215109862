import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  url = environment.url + 'api/Modules/';
  modulewisemenu = 'GetRolePermissionList'

  constructor(private _http: HttpClient) { }

  GetModulewiseRolePermission(roleId: any) {
    return this._http.get(`${this.url + this.modulewisemenu + "?roleId="}${roleId}`);
  }

  dashboardCard() {
    return this._http.get(environment.url + 'api/Dashboard/Dashboard');
  }
  CommanDashboard() {
    return this._http.get(environment.url + 'api/Dashboard/CommanDashboard');
  }
  
  getTotalRequest() {
    return this._http.get(environment.url + 'api/Dashboard/TotalRequest');
  }
  getHotelRating() {
    return this._http.get(environment.url + 'api/Dashboard/HotelRating');
  }
  decryptAES256(decString) {
    var key = CryptoJS.enc.Utf8.parse(environment.key);
    var iv = CryptoJS.enc.Utf8.parse(environment.iv);
    var decrypted = CryptoJS.AES.decrypt(decString, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  encryptAES256(text: any): any {
    var key = CryptoJS.enc.Utf8.parse(environment.key);
    var iv = CryptoJS.enc.Utf8.parse(environment.iv);
    var encryptedlogin = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key,
      { keySize: 128 / 8, iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return encryptedlogin.toString();
  }
  isValidFileType(fileName:any, fileType :any): boolean {
    // Create an object for all extension lists
    let extentionLists :any= { video: [], image: [], pdf: [], excel: [], xml: [] };
    let isValidType = false;
    extentionLists.video = ['m4v', 'avi', 'mpg', 'mp4'];
    extentionLists.image = ['jpg', 'jpeg', 'bmp', 'png', 'ico'];
    extentionLists.pdf = ['pdf'];
    extentionLists.excel = ['excel'];
    extentionLists.xml = ['xml'];
    extentionLists.csv = ['csv', 'xlsx'];

    //get the extension of the selected file.
    let fileExtension = fileName.split('.').pop().toLowerCase();
     isValidType = extentionLists[fileType].indexOf(fileExtension) > -1;
    return isValidType;
  };
}
