import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountsService } from 'src/app/services/accounts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPassword = this._fb.group({
    email: ['', [Validators.required]],
  });
  isValid: boolean = false;

  constructor(private _fb: FormBuilder, private router: Router, private _service: AccountsService) { }

  public error = (control: string, error: string) => {
    return this.forgotPassword.controls[control].hasError(error);
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }


  onSubmit() {
    this.isValid = true;
    if (this.forgotPassword.invalid) {
      return;
    }
    else {
      this._service.forgotPassword(this.forgotPassword.value).subscribe((res: any) => {
        if (res.status == 'Success' || res.status == 'success') {
          this.router.navigate(['login']);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: res.message,
            showConfirmButton: true,
          });
        }
        else if (res.status == 'Error' || res.status == 'error') {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: res.message,
            showConfirmButton: true,
          });
        }
      })

    }
  }

}
