import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse
} from "@angular/common/http";
import { tap } from "rxjs/operators";   
import { LoaderService } from "./loader.service";
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private _loaderService: LoaderService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    this._loaderService.ShowLoader();
    let token: string | null = localStorage.getItem("accessToken");

    if (token) {
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    }
    return next.handle(request).pipe(
        tap(
          req => {
            if (req instanceof HttpResponse) {
              this._loaderService.HideLoader();
            }
          },
          err => {
            this._loaderService.HideLoader();
          }
        )
      );
  }

}
