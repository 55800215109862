import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, throwError } from "rxjs";
import { Observable } from "rxjs/internal/Observable";


@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token: string | null = localStorage.getItem("accessToken");
    if (token) {
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    }
    
    
    return next.handle(request)
    .pipe(
      catchError((error: HttpErrorResponse) => {
          if ((error.status === 401)) {
              localStorage.clear();
              this.router.navigate(['/login']);
          }
          return throwError(error);
      }))
    }
}
