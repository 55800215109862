import { Directive, ElementRef, HostBinding, HostListener} from '@angular/core';

@Directive({
  selector: '[appDropdown]',
  exportAs: 'appDropDown'
})
export class DropdownDirective {
  @HostBinding('class.open') toggleDropdown = false;

  constructor(private elemRef: ElementRef) { }

  @HostListener('document:click', ['$event'])
  // @HostListener('document:touchstart', ['$event'])
  // @HostListener('document:touchend', ['$event'])
  toggle(event) {
    if (this.elemRef.nativeElement.contains(event.target)) {
      this.toggleDropdown = !this.toggleDropdown;
    } else {
      this.toggleDropdown = false;
    }
  }
}
