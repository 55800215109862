import { Component } from "@angular/core";
import { LoaderService } from "./loader.service";
@Component({
    selector: "loader",
    template: `
    <div class="dark-overlay" *ngIf="show">
    <mat-spinner mode="indeterminate" class="mat-spinner-color"></mat-spinner>
</div>
  `,
    styles: [
        `mat-spinner {    position: fixed !important;    top: 0;    left: 0;    right: 0;    bottom: 0;    margin: auto;} 
    .dark-overlay {
    background-color: rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    
}

.mat-drawer:not(.mat-drawer-side) {
    position: fixed;
}

.mat-drawer-backdrop {
    position: fixed !important;
}
.mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: #00B5F0;
}   
    `
    ]
})
export class LoaderComponent {
    show: boolean;
    constructor(private _loaderService: LoaderService) { }

    ngOnInit() {
        this._loaderService.loadState.subscribe(res => {
            this.show = res;
        });
    }
}
