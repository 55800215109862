import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  url = environment.url + 'api/Account/';
  login = 'LoginWeb';
  change = 'ChangePassword';
  forgot = 'ForgotPassword'
  reset = 'ResetPassword';

 

  constructor(private _http: HttpClient) { }

  Login(data: any) {
    return this._http.post(this.url + this.login, data);
  }

  changePassword(data: any) {
    return this._http.post(this.url + this.change, data);
  }

  resetPassword(data: any) {
    return this._http.post(this.url + this.reset, data);
  }

  forgotPassword(email: any) {
    return this._http.post(this.url + this.forgot,  email);
  }

}
