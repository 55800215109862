
<div class="content-wrapper d-flex align-items-center auth">
    <div class="row flex-grow">
        <div class="col-lg-4 mx-auto">
            <div class="auth-form-light text-left p-5">
                <div class="brand-logo text-center">
                    <img src="assets/images/Logo.png">
                </div>
                <h6 class="font-weight-light page-title">Reset Password</h6>
                <form class="pt-3" [formGroup]="resetPassword">
                    <div class="form-group">
                        <input type="email" class="form-control form-control-lg d-none" formControlName="email"
                            id="exampleInputEmail1" placeholder="Email" [(ngModel)]="email" hidden>
                        <input type="email" class="form-control form-control-lg d-none" formControlName="token"
                            id="exampleInputEmail1" placeholder="Token" [(ngModel)]="token" hidden>
                        <div class="mt-1">
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group mb-3">
                            <input type="password" class="form-control form-control-lg" formControlName="newPassword"
                                id="exampleInputPassword1" placeholder="New Password" matInput
                                [type]="hideNewPassword ? 'password' : 'text'">
                            <span class="input-group-text" (click)="hideNewPassword = !hideNewPassword">
                                <mat-icon *ngIf="!hideNewPassword" >visibility</mat-icon>   
                                <mat-icon *ngIf="hideNewPassword">visibility_off</mat-icon>
                            </span>
                        </div>
                        <mat-error class="text-small text-danger" *ngIf="submitted && error('newPassword' ,'required')"> Please enter new
                            password.
                        </mat-error>
                        <mat-error class="text-small text-danger" *ngIf="error('newPassword' ,'pattern')"> Password should have Minimum 8
                            Characters atleast 1
                            uppercase 1 lowercase 1 special character 1 number.
                        </mat-error>
                        <mat-error class="text-small text-danger mt-1" *ngIf="error('newPassword' ,'cannotContainSpace')">
                            <br> Password is not allowed space.
                         </mat-error>
                     
                    </div>
                    <div class="form-group">
                        <div class="input-group mb-3">
                            <input type="password" class="form-control form-control-lg"
                                formControlName="confirmPassword" id="exampleInputPassword1"
                                placeholder="Confirm Password" matInput
                                [type]="hideConfirmPassword ? 'password' : 'text'">
                            <span class="input-group-text" (click)="hideConfirmPassword = !hideConfirmPassword">
                                <mat-icon *ngIf="!hideConfirmPassword" >visibility</mat-icon>   
                                <mat-icon *ngIf="hideConfirmPassword">visibility_off</mat-icon>
                            </span>
                        </div>
                        <div class="mt-1">
                            <div *ngIf="submitted && resetPassword.get('confirmPassword').errors"
                                class="text-small text-danger">
                                <div *ngIf="resetPassword.get('confirmPassword').errors.required">Confirm Password
                                    is
                                    required</div>

                                <div *ngIf="resetPassword.get('confirmPassword').errors.confirmPasswordValidator">
                                    Passsword and
                                    Confirm Password didn't match. </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <a class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                            (click)="onSubmit()">Reset</a>
                    </div>
                    <div class="my-2 d-flex justify-content-between align-items-center">
                    </div>
                    <div class="mb-2">
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>