import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangePasswordComponent } from './accounts/change-password/change-password.component';
import { ForgotPasswordComponent } from './accounts/forgot-password/forgot-password.component';
import { LoginComponent } from './accounts/login/login.component';
import { ResetPasswordComponent } from './accounts/reset-password/reset-password.component';
import { AuthGuardGuard } from './guards/auth-guard.guard';
import { RoleGuard } from './guards/role.guard';
import { SideNavbarComponent } from './side-navbar/side-navbar.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: "travel-desk", component: SideNavbarComponent, canActivate: [AuthGuardGuard, RoleGuard], children: [
      { path: 'change-password', component: ChangePasswordComponent , title:'Change password' },
      { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) ,title:'Dashboard' },
      { path: 'travel-team-members',title:'Travel Team Members', loadChildren: () => import('./pages/travel-team-members/travel-team-members.module').then(m => m.TravelTeamMembersModule)},
      { path: 'hotels',title:'Hotels', loadChildren: () => import('./pages/hotels/hotels.module').then(m => m.HotelsModule) },
      { path: 'travel-requests',title:'Travel Requests', loadChildren: () => import('./pages/travel-requests/travel-requests.module').then(m => m.TravelRequestsModule) },
      { path: 'employees', title:'Employees',loadChildren: () => import('./pages/employees/employees.module').then(m => m.EmployeesModule) },
    ]
  },
 
  { path: "**", redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
