import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  role: string;
  constructor(private route: Router,private commonService: CommonService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.role = localStorage.getItem('roleName');

    if (this.role == 'Head' || this.role == 'Member') {
      return true;
    }
    else (this.role == "" && this.role == undefined && this.role == null)
    this.route.navigate(['']);
    return false;
  }

}
